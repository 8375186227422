.CustomAssetFormDialog {}

.DialogTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FormContent {
    display: flex;
    flex-direction: column;
}

.AssetBox {
    display: flex;
    flex-direction: column;
    border: 2px solid grey;
    padding: 15px;
    border-radius: 3px;
}
