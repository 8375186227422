.AssetList {
    overflow: auto;
    max-height: 100%;
}

.SearchBoxAndList {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.NoAssetsMessage {
    text-align: center;
    padding: 10px;
}

.SelectedAssetBox {
    border-radius: 4px;
    border: 2px solid orange;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
}

.TrainPosition {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px dashed gray;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    color: gray;
}

.TrainPositionWrapper {
    padding: 5px;
}

.AddAssetButton {
    text-align: right;
    position: sticky;
    padding: 15px;
    bottom: 0;
}
