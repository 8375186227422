@import "../../Colours.module.css";

.Dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--color-gioconda-grey-1);
}

.NavControls {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}

.Logo img {
    width: 220px;
}

.Spacer {
    flex-grow: 1;
}

.TopDropdown {
    margin-right: 15px;
}

.ProjectControls {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.LeftBar {
    margin-right: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0
}

.ResizeOnlyStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.Middle {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.VideoPlayerContainer {
    padding: 2px;
    height: auto;
    flex: 0 1 100%;
    display: flex;
    justify-content: center;
}

.CardContainer {
    margin-bottom: 5px;
}

.CentreResize {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RightBar {
    padding: 5px;
    width: 330px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
}

.BottomControlBar {
    flex-basis: 30px;
    display: flex;
    flex-direction: row;
    padding: 0 0 15px 0;
    justify-content: center;
}

.FullScreenControlBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(255 255 255 / 80%);
    padding: 15px;
}

.DragHandle {
    width: 10px;
    height: 40px;
    background-color: grey;
    display: block;
    flex-shrink: 0;
}
